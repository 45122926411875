<template>
    <v-container class="pa-0" fluid>
        <div v-if="loading" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <v-card v-else class="transparent" outlined>
            <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="mb-4 elevation-3">
                <span class="primary--text">{{ this.shiftName +" "+ $t('accounting.lang_shiftFrom') +" "+ this.startShift +" "+ $t('generic.lang_to') +" "+ this.endShift}}</span>

                <v-spacer/>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="loadingPrinting" :loading="loadingPrinting" @click="printShiftReport"
                               color="primary" icon v-on="on">
                            <v-icon large>print</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('generic.lang_reprint')}}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="loadingDownload" :loading="loadingDownload" @click="downloadDailyReportPDF"
                               color="warning" icon v-on="on">
                            <v-icon large>cloud_download</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('generic.lang_download')}}</span>
                </v-tooltip>
            </v-card-title>

            <v-divider/>

            <v-card-subtitle>
                <v-row class="ma-0 pa-0 text-center">
                    <v-col class="row mx-auto text-center" cols="12">
                        <v-col cols="12" md="4">
                            <v-card class="cards text-center" elevation="6">
                                <v-card-title class="pt-6 pb-6">
                                    <span class="mx-auto bolds">{{this.total | currency}}</span>
                                </v-card-title>
                                <v-divider class="pa-0 ma-0"/>
                                <v-card-subtitle class="ma-0 pa-1">
                                    <strong>{{$t('generic.lang_total')}}</strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-card class="cards text-center" elevation="6">
                                <v-card-title class="pt-6 pb-6">
                                    <span class="mx-auto bolds">{{this.subtotal | currency}}</span>
                                </v-card-title>
                                <v-divider class="pa-0 ma-0"/>
                                <v-card-subtitle class="ma-0 pa-1">
                                    <strong>{{$t('generic.lang_subtotal')}}</strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-card class="cards text-center" elevation="6">
                                <v-card-title class="pt-6 pb-6">
                                    <span class="mx-auto bolds">{{this.tax | currency}}</span>
                                </v-card-title>
                                <v-divider class="pa-0 ma-0"/>
                                <v-card-subtitle class="ma-0 pa-1">
                                    <strong>{{$t('accounting.lang_taxes')}}</strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card-subtitle>

            <v-card-text :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="pa-0 elevation-6">
                <v-tabs fixed-tabs v-model="tabs">
                    <v-tab>
                        {{$t('accounting.lang_salesStatistics')}}
                    </v-tab>
                    <v-tab>{{$t('accounting.lang_salesByItemgroup')}}</v-tab>
                    <v-tab>{{$t('accounting.lang_userSalesStats')}}</v-tab>
                    <v-tab>{{$t('accounting.lang_kassenbuch')}}</v-tab>

                </v-tabs>

                <v-container class="pa-0 ma-0" fluid>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" v-if="this.tabs == 0">
                            <umsatz-component/>
                        </v-col>

                        <v-col cols="12" v-if="this.tabs == 1">
                            <umsatz-ware-group-component/>
                        </v-col>

                        <v-col cols="12" v-if="this.tabs == 2">
                            <umsatz-cashier-component :start="$moment(this.startShift,'DD.MM.yyyy / HH:mm').unix()" :end="$moment(this.endShift,'DD.MM.yyyy / HH:mm').unix()"/>
                        </v-col>

                        <v-col cols="12" v-if="this.tabs == 3">
                            <cash-book-component/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <!-- DAILY REPORT PDF DIALOG -->
        <v-dialog v-model="displayDailyReportDialog" persistent width="800">
            <v-card>
                <v-card-text style="padding: 0; height: 600px;">
                    <iframe v-if="iframePDFContent.length > 0" :src="iframePDFContent" width="800" height="600"/>
                </v-card-text>

                <v-card-actions style="background-color: white;">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" dark text @click="displayDailyReportDialog = false">{{ $t('generic.lang_close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import PrintDailyComponent from "./PrintDailyComponent";
    import DailyArchiveComponent from "./DailyArchiveComponent";
    import {ENDPOINTS} from "../../../config";
    import UmsatzWareGroupComponent from "./UmsatzWareGroupComponent";
    import UmsatzComponent from "./UmsatzComponent";
    import UmsatzCashierComponent from "./UmsatzCashierComponent";
    import CashBookComponent from "./CashBookComponent";
    import {Events} from "../../../plugins/events";
    import {mapGetters, mapState} from "vuex";
    import FileSaver from 'file-saver';
    import {printDataFromPrinter} from "../../../plugins/printing/printerController";

    export default {
        name: "DailyReportComponent",

        components: {
            UmsatzWareGroupComponent,
            PrintDailyComponent,
            DailyArchiveComponent,
            UmsatzComponent,
            UmsatzCashierComponent,
            CashBookComponent
        },
        computed: {
            ...mapState([
                'pos',
                'api',
                'tse'
            ]),
            ...mapState('printer', {
                printers: state => state.printers
            }),
            ...mapGetters({
                fiscalClient: 'tse/fiscalClient'
            }),
            invoicePrinter() {
                return this.printers.find((printer) => {
                    if (printer.cashierID.includes(this.api.auth.cashierID)) {
                        if (printer.type === 1) {
                            return true;
                        }
                    }
                });
            }
        },

        data: () => {
            return {
                ENDPOINTS,
                loading: false,
                loadingDownload: false,
                loadingPrinting: false,
                startShift: "",
                endShift: "",
                shiftName: "",
                total: "",
                subtotal: "",
                gross: "",
                tax: "",
                dialog: false,
                tabs: null,
                iframePDFContent: "",
                displayDailyReportDialog: false,
            }
        },

        methods: {
            loadData() {
                this.loading = true;

                this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.GET_DETAILS_BASE_DATA, {
                    reportID: this.$route.params.id
                }).then((res) => {
                    if (res.data.success) {
                        this.loading = false;

                        this.shiftName = res.data.shifts.shiftName;
                        this.startShift = res.data.shifts.startShift;
                        this.endShift = res.data.shifts.endShift;
                        this.subtotal = res.data.netTotal;
                        this.total = res.data.total;
                        this.tax = res.data.tax;
                    }
                });
            },

          downloadDailyReportPDF() {
            this.loadingDownload = true;
            this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.PRINTA4, {
                  reportID: this.$route.params.id
                },
                {
                  responseType: 'arraybuffer',
                }).then((res) => {
              //GENERATE PDF CONTENT FOR iFRAME
              const blobContent = new Blob([res.data], {type: "application/pdf"});
              this.iframePDFContent = URL.createObjectURL(blobContent);

              this.displayDailyReportDialog = true;

              //DOWNLOAD INVOICE AS PDF
              FileSaver.saveAs(new Blob([res.data], {
                type: "application/pdf"
              }), "Tagesabschluss.pdf");

              this.loadingDownload = false;
            }).catch((err) => {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }).finally(() => {
              this.loadingDownload = false;
            })
          },
            printShiftReport() {
                this.loadingPrinting = true;



                this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.PRINTZ, {
                    reportID: this.$route.params.id
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {

                      // EPSON EPOS PRINT
                      printDataFromPrinter(this.invoicePrinter,res.data.printXML).then(()=>{

                      }).catch((err)=>{
                        Events.$emit("showSnackbar", {
                          message: this.$t('generic.lang_errorOccurred'),
                          color: "error"
                        });
                      }).finally(()=>{
                        this.loadingPrinting=false;
                      })

                    } else {
                        this.loadingPrinting = false;

                        Events.$emit("showSnackbar", {
                            message: this.$t("accounting.lang_dailyClosingCouldNotBePrinted"),
                            color: "error"
                        });
                    }

                }).catch(() => {
                    this.loadingPrinting = false;

                    Events.$emit("showSnackbar", {
                        message: this.$t("accounting.lang_dailyClosingCouldNotBePrinted"),
                        color: "error"
                    });
                });
            }
        },

        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    .bolds {
        font-size: 30px;
    }

    #cards {
        max-width: 200px !important;
    }

    #showXReport_iframe {
        width: 100% !important;
        height: 100% !important;
        border: none;
    }

    #pdf_card {
        width: 100% !important;
        height: 100% !important;
    }
</style>