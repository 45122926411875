<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">

                <v-simple-table  height="500px">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left text-uppercase">{{ $t('accounting.lang_bookingDate') }}</th>
                            <th class="text-left text-uppercase">{{ $t('accounting.lang_receiptDate') }}</th>
                            <th class="text-left text-uppercase">{{ $t('accounting.langAccountingAccount') }}</th>
                            <th class="text-left text-uppercase">{{ $t('accounting.lang_income') }}</th>

                            <th class="text-left text-uppercase">{{ $t('accounting.lang_expense') }}</th>

                            <th class="text-left text-uppercase">{{ $t('accounting.lang_currentCash') }}</th>
                            <th class="text-left text-uppercase">{{ $t('accounting.lang_invoiceNo') }}</th>
                            <th class="text-left text-uppercase">{{ $t('accounting.lang_percentTax') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :key="item.name" v-for="item in CashBookTable">
                            <td>{{ item[1] }}</td>
                            <td>{{ item[2] }}</td>
                            <td>{{ item[3] + " " + item[4] }}</td>
                            <td>{{ item[5] | currency}}</td>

                            <td>{{ item[6] | currency}}</td>

                            <td>{{ item[7] | currency}}</td>
                            <td>{{ item[8] }}</td>



                            <td>{{ item[9] }} %</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from "../../../config";
    import VueApexCharts from 'vue-apexcharts'

    export default {
        name: "UmsatzWareGroupComponent",
        components: {
            'apexchart': VueApexCharts
        },
        data: () => {
            return {
                ENDPOINTS,
                options: null,
                series: null,
                CashBookTable: null,
            }
        },
        mounted() {
            this.loadData();
        }
        ,
        methods: {
            loadData() {


                this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.CASHBOOK, {
                    reportID: this.$route.params.id
                }).then((res) => {

                 //   ////console.log(res);
                    if (res.data && res.data.success) {
                        this.CashBookTable = res.data.data;
                    }


                });
            }
            ,
        }

    }
</script>

<style scoped>

</style>
