<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <apexchart :options="this.options" :series="this.series"
                           type="bar" v-if="this.series != null && this.options != null"></apexchart>
            </v-col>

      <v-col cols="12">
        <v-data-table
            :headers="this.headers"
            :items="this.UMSATZWAREGROUPTABLE"
            :sort-by="['date']"
            :sort-desc="[false, true]"
            class="elevation-0"
            multi-sort
            v-if="this.UMSATZWAREGROUPTABLE != null"
        >
          <template v-slot:item.umsatz="{ item }">
            {{ item.umsatz | currency }}
          </template>

          <template v-slot:item.solds="{ item }">
            {{ item.solds }}
          </template>
          <template v-slot:item.umsatzPerSold="{ item }">
            {{ item.umsatzPerSold | currency }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import VueApexCharts from 'vue-apexcharts'

var self=null;
export default {
  name: "UmsatzWareGroupComponent",
  components: {
    'apexchart': VueApexCharts
  },
  data: () => {
    return {
      ENDPOINTS,
      options: null,
      series: null,
      UMSATZWAREGROUPTABLE: null
    }
  },
  mounted() {
    this.loadData();
    self=this;
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_waregroup'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: this.$t('accounting.lang_turnover'), value: 'umsatz'},
        {text: this.$t('generic.lang_sales'), value: 'solds'},
        {text: this.$t('accounting.lang_turnover')+ '/' + this.$t('generic.lang_Verkauf'), value: 'umsatzPerSold'},
        {
          text: this.$t('accounting.lang_returns'), value: 'retouren'
        }

      ]
    }
  },
  methods: {
    loadData() {
      const chart = this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.UMSATZWAREGROUP, {
            reportID: this.$route.params.id
          }),
          table = this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.UMSATZWAREGROUPTABLE, {
            reportID: this.$route.params.id
          });

      Promise.all([chart, table]).then(this.axios.spread((...res) => {
        this.UMSATZWAREGROUPTABLE = res[1].data.data;

        if (res[0].data && res[0].data.success) {
          this.series=[{
            data: res[0].data.data.map(d => d.Umsatz),
            name: this.$t('generic.lang_sale'),
          }];
          this.options = {
            chart: {
              type: 'bar'
            },
            theme: {
              mode: this.$vuetify.theme.dark? 'dark' : 'light',   
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return self.$options.filters.currency(val);
              },
              offsetY: -20,
              style: {
                fontSize: '14px',
                colors: ["#304758"]
              }
            },
            yaxis: [
              {
                title: {
                  text: this.$t('generic.lang_total')
                },
                labels: {
                  formatter: function (val) {
                    return self.$options.filters.currency(val);
                  }
                }
              },
            ],
            xaxis: {
              title: {
                text: this.$t('generic.lang_waregroup')
              },
              categories: res[0].data.data.map(d => d.name + ''),
            }
          };

        }


      }));
    },
  }

}
</script>

<style scoped>

</style>